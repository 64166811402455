<template>
	<div class="row">
		<div :class="actualRecord.addresses.length ? 'col-md-6' : 'col-md-12'">
			<a-form class="mb-4" :form="form" @submit="handleSubmit">
				<div class="row">
					<div class="col-md-6">
						<a-form-item class="m0" label="Nombre">
							<a-input
								v-decorator="[
									'description',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								class="text-uppercase"
							/>
						</a-form-item>
					</div>
					<div class="col-md-6">
						<a-form-item class="m0" label="Calle">
							<a-input
								v-decorator="[
									'street_name',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								class="text-uppercase"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item class="m0" label="No. exterior">
							<a-input
								v-decorator="[
									'outdoor_number',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								class="text-uppercase"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item class="m0" label="No. interior">
							<a-input v-decorator="['interior_number']" autocomplete="off" class="text-uppercase" />
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item class="m0" label="Código Postal">
							<a-input
								v-mask="'#####'"
								v-decorator="[
									'postal_code',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								class="text-uppercase"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item class="m0" label="Colonia">
							<a-input
								v-decorator="[
									'suburb',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								class="text-uppercase"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item class="m0" label="Municipio">
							<a-input
								v-decorator="[
									'municipality',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								class="text-uppercase"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item class="m0" label="Estado">
							<a-input
								v-decorator="[
									'state',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								class="text-uppercase"
							/>
						</a-form-item>
					</div>
					<div class="col-md-12">
						<a-form-item class="m0" label="Link de Google Maps">
							<a-input
								v-decorator="[
									'link',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<hr class="hrText" />
					</div>
					<div class="col-md-4 text-left">
						<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
					</div>
					<div class="col-md-8 text-right">
						<a-button class="btn btn-danger" v-if="showCancelButton" @click="onCancelEdit">Cancelar</a-button>
						<a-button icon="save" class="btn btn-success ml7" htmlType="submit">Guardar</a-button>
					</div>
				</div>
			</a-form>
		</div>
		<div class="col-md-6" v-if="actualRecord.addresses.length">
			<a-collapse accordion>
				<a-collapse-panel v-for="(element, index) in actualRecord.addresses" :key="index" :header="`${element.description.toUpperCase()}`">
					<table class="table table-bordered table-condensed table-striped m0 tablePadding25">
						<tbody>
							<tr>
								<td width="35%"><b>Nombre</b></td>
								<td class="text-uppercase">{{ element.description }}</td>
							</tr>
							<tr>
								<td width="35%"><b>Calle</b></td>
								<td class="text-uppercase">{{ element.street_name }}</td>
							</tr>
							<tr>
								<td width="35%"><b>No. exterior</b></td>
								<td class="text-uppercase">{{ element.outdoor_number }}</td>
							</tr>
							<tr>
								<td width="35%"><b>No. interior</b></td>
								<td class="text-uppercase">{{ element.interior_number | replaceIfLogicalFalse('-') }}</td>
							</tr>
							<tr>
								<td width="35%"><b>Código Postal</b></td>
								<td class="text-uppercase">{{ element.postal_code }}</td>
							</tr>
							<tr>
								<td width="35%"><b>Colonia</b></td>
								<td class="text-uppercase">{{ element.suburb | replaceIfLogicalFalse('-') }}</td>
							</tr>
							<tr>
								<td width="35%"><b>Municipio</b></td>
								<td class="text-uppercase">{{ element.municipality | replaceIfLogicalFalse('-') }}</td>
							</tr>
							<tr>
								<td width="35%"><b>Estado</b></td>
								<td class="text-uppercase">{{ element.state | replaceIfLogicalFalse('-') }}</td>
							</tr>
							<tr>
								<td width="35%"><b>Link de Google Maps</b></td>
								<td><a :href="element.link" target="new">Abrir link</a></td>
							</tr>
						</tbody>
					</table>
					<div class="row">
						<div class="col-md-12 text-right" style="padding: 10px 25px" v-if="isEditable">
							<a-button class="btn btn-danger" size="small" icon="delete" @click="onDeleteElement(element.id)" />
							<a-button class="btn btn-info ml7" size="small" icon="edit" @click="onEditElement(element.id)" />
						</div>
					</div>
				</a-collapse-panel>
			</a-collapse>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { mask } from 'vue-the-mask'
import utilities from '@/services/utilities'

export default {
	name: 'customerAddressComponent',
	props: {
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('customers', ['actualRecord']),
	},
	data() {
		return {
			form: this.$form.createForm(this),
			showCancelButton: false,
			actualTaxInformation: 0,
		}
	},
	methods: {
		onCancel() {
			this.$router.replace('/customers')
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					let payload = {
						customer_id: Number(this.$route.params.id),
						...values,
					}
					if (this.showCancelButton) {
						payload.id = this.actualRecord.addresses[this.actualTaxInformation].id
						await this.$store.dispatch('customers/UPDATE_ADDRESS', payload)
					} else {
						await this.$store.dispatch('customers/CREATE_ADDRESS', payload)
					}

					this.$store.dispatch('customers/GET_ONE', this.$route.params.id)
					this.onCancelEdit()
				}
			})
		},
		setLocalData(values) {
			this.form.setFieldsValue({
				description: utilities.objectValidate(values, 'description', ''),
				street_name: utilities.objectValidate(values, 'street_name', ''),
				outdoor_number: utilities.objectValidate(values, 'outdoor_number', ''),
				interior_number: utilities.objectValidate(values, 'interior_number', ''),
				postal_code: utilities.objectValidate(values, 'postal_code', ''),
				suburb: utilities.objectValidate(values, 'suburb', ''),
				municipality: utilities.objectValidate(values, 'municipality', ''),
				state: utilities.objectValidate(values, 'state', ''),
				link: utilities.objectValidate(values, 'link', ''),
			})
		},
		onCancelEdit() {
			this.form.resetFields()
			this.showCancelButton = false
		},
		onDeleteElement(customer_id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el registro del cliente?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('customers/DELETE_ADDRESS', customer_id).then(async () => {
						this.$store.dispatch('customers/GET_ONE', this.$route.params.id)
					})
				}
			})
		},
		onEditElement(id) {
			let values = _.cloneDeep(this.actualRecord.addresses.find((e) => e.id == id))
			this.showCancelButton = true
			this.setLocalData(values)
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>