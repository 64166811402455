<template>
	<div class="card">
		<div class="card-body">
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" tab="Información General" value="Información General" force-render>
					<customersPartialsGeneral />
				</a-tab-pane>
				<a-tab-pane key="2" tab="Información Fiscal" value="Información Fiscal" force-render v-if="!isNewRecord">
					<customersPartialsTax />
				</a-tab-pane>
				<a-tab-pane key="3" tab="Direcciones de Entrega" value="Direcciones de Entrega" force-render v-if="!isNewRecord">
					<addressInformation />
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import customersPartialsGeneral from '@/components/customers/partials/general'
import customersPartialsTax from '@/components/customers/partials/tax'
import addressInformation from '@/components/customers/partials/addressInformation'

export default {
	name: 'customerDetailView',
	components: {
		customersPartialsGeneral,
		customersPartialsTax,
		addressInformation,
	},
	computed: {
		...mapGetters('customers', ['actualRecord']),
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
	},
	mounted() {
		if (!this.isNewRecord && utilities.objectValidate(this.actualRecord, 'general.id', false)) {
			// Solicitamos carga de registro
			this.$store.dispatch('customers/GET_ONE', this.$route.params.id)
		}
	},
	methods: {
		reload() {
			this.$store.dispatch('customers/GET_ONE', this.$route.params.id)
		},
	},
}
</script>